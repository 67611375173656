<template>
  <v-container id="regular-tables-view" fluid tag="section">
    <v-card>
      <v-card-title>
        <v-toolbar flat dense>
          <v-btn icon elevation="2" color="green" to="/learnerform">
            <v-icon>mdi-account-multiple-plus-outline</v-icon>
          </v-btn>
          <v-toolbar-title>Learners </v-toolbar-title>

          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="learners"
          :items-per-page="10"
          class="elevation-1"
          single-select
          :search="search"
          v-model="selLearner"
          dense
        >
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" color="primary" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small color="error" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
            <v-icon medium color="orange"  v-if="item.disabled">
              mdi-hand-back-right
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <div class="py-3" />
  </v-container>
</template>

<script>

import { get, sync,dispatch } from "vuex-pathify";

export default {
  name: "ProgramDialog",

  data() {
    return {
      dialogDelete: false,
      headers: [
      { text: "Actions", value: "actions", sortable: false },
        { text: "Surname", value: "surname" },
        { text: "Given Name", value: "gname" },
        { text: "Middle Name", value: "mname" },
        { text: "Suffix Name", value: "sfxname" },
        { text: "LRN", value: "lrn" },
        { text: "Age", value: "age" },
        { text: "Contact", value: "contact" },
        { text: "Date of Birth", value: "dob" },
        { text: "Gender", value: "gender" },
        { text: "Emergency: Person Contact ", value: "emrgcyContactName" },
        { text: "Emergency: Contact No.", value: "emrgcyContactNo" },
       
      ],
      search: "",
    };
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },

    // selLearner(val) {
    //   this.selLearner = val[0];
    //   this.$router.push("/learnersassessment");
    // },
  },

  computed: {
    ...get("learners", ["learners"]),
    ...sync("learners", ["selLearner","learners"]),   
  },

  beforeMount(){
    this.load()
  },

  methods: {

    load(){
      dispatch('learners/load')
      this.selLearner=''  
    },
    editItem(item) {
      this.selLearner = item;
      this.$router.push({name:"Learner Form"});
    },

    deleteItem(item) {
      this.selLearner = item;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.selLearner.method="DELETE"
      await dispatch('learners/delete')
      load();
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
    },
  },
};
</script>
